/**
 * Header styles
 * DBS>Interactive
 *
 * 		site-header - the page's main header element
 * 			site-header__branding - the company logo
 * 			site-header__navigation - the site navigation
 * 				NOTE: Put navigation styles in _navigation.scss.
 *
 * ************************************************************************
 * This file is for the general header/branding styles. For specific
 * menu/navigation files, use _navigation.scss.
 * ************************************************************************
 */

.site-header {
	position: relative;
	z-index: 1000;

	&__container {
		display: flex;
			align-items: center;
			justify-content: space-between;
		padding: 0 $spacing;
	}

	/**
	 * Company logo in site header
	 */
	&__branding {
		padding: .5rem 0;

		img {width: 100%;}

		/**
		 * Anchor tag that wraps svg element (logo graphic) for site homepage link
		 */
		a {
			display: block;
			line-height: 0;
			margin-left: -.25em; // Move the left edge of the logo all the way to the edge of the container
			max-width: 6em;
			padding: .5em .25em;

			@include media($mobileNavBP) {
				max-width: 9em;
                width: 12vw;
			}
		}
	}

	@include media($mobileNavBP) {
		border-bottom: 1px solid rgba($white, .2);
		position: absolute;
			top: 0;
			left: 0;
		width: 100%;
	}
}
