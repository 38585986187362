/**
 * To add a new font:
 * DBS>Interactive
 *
 * Take the ../fonts and upload them here:
 * https://transfonter.org/
 *
 * Download the resulting files and copy the woff and woff2
 * files into the appropriate directory.
 *
 * Make sure that the font-weight and font-style match the appropriate font files.
 *
 * We generally remove all of the unnecessary glyphs from the font family to make the files as
 * small as possible. If the font is a Google Font, you can copy all of its available glyphs
 * by clicking the "Glyphs" tab on the font's page. Paste those into the "characters" field on 
 * Transfonter and delete all of the characters that are not typically going to be used on an  
 * English language site. When you do this correctly the files downloaded from Transfonter will
 * be prefixed with "subset-". If the site has multiple languages, then don't do that.
 */

/**
 * Noto Serif
 */
@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/noto-serif/subset-NotoSerifDisplay-Thin.woff2') format('woff2'),
         url('../fonts/noto-serif/subset-NotoSerifDisplay-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/noto-serif/subset-NotoSerifDisplay-Light.woff2') format('woff2'),
         url('../fonts/noto-serif/subset-NotoSerifDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/noto-serif/subset-NotoSerifDisplay-Regular.woff2') format('woff2'),
         url('../fonts/noto-serif/subset-NotoSerifDisplay-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/noto-serif/subset-NotoSerifDisplay-Medium.woff2') format('woff2'),
         url('../fonts/noto-serif/subset-NotoSerifDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/noto-serif/subset-NotoSerifDisplay-SemiBold.woff2') format('woff2'),
         url('../fonts/noto-serif/subset-NotoSerifDisplay-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/noto-serif/subset-NotoSerifDisplay-Bold.woff2') format('woff2'),
         url('../fonts/noto-serif/subset-NotoSerifDisplay-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/noto-serif/subset-NotoSerifDisplay-ExtraBold.woff2') format('woff2'),
         url('../fonts/noto-serif/subset-NotoSerifDisplay-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Serif';
    src: url('../fonts/noto-serif/subset-NotoSerifDisplay-Black.woff2') format('woff2'),
         url('../fonts/noto-serif/subset-NotoSerifDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

/**
 * Poppins
 */
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/subset-Poppins-Thin.woff2') format('woff2'),
         url('../fonts/poppins/subset-Poppins-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/subset-Poppins-ExtraLight.woff2') format('woff2'),
         url('../fonts/poppins/subset-Poppins-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/subset-Poppins-Light.woff2') format('woff2'),
         url('../fonts/poppins/subset-Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/subset-Poppins-Regular.woff2') format('woff2'),
         url('../fonts/poppins/subset-Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/subset-Poppins-Medium.woff2') format('woff2'),
         url('../fonts/poppins/subset-Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/subset-Poppins-SemiBold.woff2') format('woff2'),
         url('../fonts/poppins/subset-Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/subset-Poppins-Bold.woff2') format('woff2'),
         url('../fonts/poppins/subset-Poppins-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/subset-Poppins-ExtraBold.woff2') format('woff2'),
         url('../fonts/poppins/subset-Poppins-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/subset-Poppins-Black.woff2') format('woff2'),
         url('../fonts/poppins/subset-Poppins-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}