/**
 * Slate Navigation Styles
 * DBS>Interactive
 *
 * ************************************************************************
 * This file is for the navigation menu in the header - both the mobile and
 * desktop menu. Keep header/branding specific styles in _header.scss.
 * ************************************************************************
 *
 * 		menu-toggle - the hamburger menu button
 * 			open - the class given when the toggle button is in the open state
 * 			menu-toggle__bar - the individual hamburger bars
 * 		submenu-toggle - a submenu toggle button
 * 			open - the class given when the toggle button is in the open state
 *
 * 		site-header__navigation - the header container for all site header
 * 			navigation.
 *
 * 			secondary-menu - the (nav element) container for the secondary menu
 * 			main-menu - the (nav element) container for the main menu
 *
 * 		menu - the container class for a menu ( ul element )
 * 			open - the class added to a ( mobile ) menu that is opened
 *
 * 			menu__item - a menu item ( li element )
 * 				menu__item--active - the active item ( current page )
 * 				menu__item--parent - an item that has a submenu
 *
 * 			menu__submenu - a submenu element ( ul element )
 * 				menu__submenu--l1 - a first level submenu
 * 				menu__submenu--l2 - etc...
 *
 */


/**
 * Hamburger Icon
 */
 .menu-toggle {
	background-color: transparent;
	border: 0;
	display: block;
	height: 3rem;
	margin-right: -.5em; // Move the right edge of the icon all the way to the edge of the container
	padding: 0.5em;
	width: 3em;

	&__bar {
		background: $white;
		display: block;
		height: 3px;
		margin: 0 auto 6px;
		opacity: 1;
		transition: transform 0.25s, width 0.25s;
		width: 100%;
		&:last-child { margin-bottom: 0; }
	}

	&.open > .menu-toggle__bar {
		&:nth-child(1) { transform: translate(0, 9px) rotate(45deg); }
		&:nth-child(2) { width: 0; }
		&:nth-child(3) { transform: translate(0, -9px) rotate(-45deg); }
	}

	// Don't display in desktop
	@include media($mobileNavBP) {
		display: none;
	}
}

/**
 * Submenu Toggle Icon
 */
.submenu-toggle {
	background-color: transparent;
	border: 0;
	height: 3.75rem;
	margin: 0;
	padding: 0;
	position: absolute;
		top:  0;
		right: 0;
	transition: transform 0.25s ease-in-out;
	transform: none;
	width: 3rem;
	z-index: 1;

	svg {
		height: 3.75rem;
		width: .75rem;
		transition: transform .25s ease-in-out;

        path {fill: $white;}
	}

	&.open {
		svg {
			transform: rotate(180deg);
		}
	}

	// Desktop styles
	@include media($mobileNavBP) {
		height: 2rem;
        opacity: 0;
		top: 50%;
		transform: translateY(-53%);
		right: -1.25rem;
		width: 2rem;

        &:focus {
            opacity: 1;
        }

		svg {height: 100%;}

		.menu__submenu > .menu__item > & {
			opacity: 1;
			right: 0;
			transform: translateY(-50%) rotate(-90deg);
		}
	}
}

.subitem .submenu-toggle {
	@include media($mobileNavBP) {
		position: absolute;
			top: 50%;
	}
}

/**
 * General Menu Styles
 *
 * These styles apply to both the mobile and the desktop menu.
 */
.site-header__navigation > nav {
	font-family: $bodyFont;
	z-index: 100;
	// Resets
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	a {
		color: $white;
		display: block;
		font-size: 1em;
		text-decoration: none;
	}

    .menu__item.nav-button {
        a {
            background: white;
            border: 1px solid white;
            border-radius: 2rem;
            color: $bodyBackground;
            font-size: 1rem;
            padding: .75rem 2rem;
            transition: .25s background, .25s color;

            &:hover,
            &:focus {
                background: $bodyBackground;
                color: $white;
            }
        }
    }
}

/**
 * Mobile Menu Styles
 */
@include media($mobileNavBPMax, 'max') {

	.site-header__navigation {
		background-color: $bodyBackground;
		border-bottom: 1px solid $grayLighter;
		max-height: 100vh;
		min-height: 100vh;
		position: absolute;
			top: 100%;
			left: 0;
		transform: translate3d(-100%,0,0);
		transition: transform .25s ease-in-out, visibility 0s .25s linear;
		visibility: hidden;
		width: 90vw;

		/**
		 * Styles for when the mobile menu is opened.
		 */
		 &.open {
			transform: translate3d(0,0,0);
			transition: transform .25s ease-in-out, visibility 0s 0s linear;
			visibility: visible;
		}
	}

	.menu {

		/**
		 * Individual menu item
		 */
		&__item {
			border-bottom: 1px solid rgba($white, .2);
			display: block;
			position: relative;

			&:last-child {border: none}

			a {
				padding: 1em 1.5em;
			}

			&.nav-button {
				padding: 1.5rem;

				a {
					text-align: center;
				}
			}
		}

		/**
		 * Submenu
		 */
		&__submenu {
			background-color: $charcoal;
			max-height: 0;
			overflow: hidden;

			/**
			 * Submenus when hovering parent
			 */
			.open > & {
				max-height: none;
			}

			/**
			 * Submenu item styles
			 */
			.menu__item {
				a {
					padding-left: $spacing*2;
				}
			}

			/**
			 * Sub-Submenus
			 */
			& & {
				a {
					padding-left: $spacing*3;
				}
			}
		}
	}
}

/**
 * Desktop Menu Styles
 */
@include media($mobileNavBP) {
	.main-menu{
		display: flex;
			justify-content: flex-end;
	}

	.main-menu {
		.menu > .menu__item {
			> a {
                font-size: 1.125rem;
                padding: 2rem 1.5rem;
				
				&:hover,
				&:focus,
				&:active {
					color: $teal;
				}
            }
		}
	}

	.menu {
		display: flex;
		    align-items: center;
			flex-direction: row;
			justify-content: flex-end;
		position: relative;

		/**
		 * Individual menu item
		 */
		&__item {
			display: block;
			margin: 0;
			position: relative;

			a {
				padding: .25rem .5rem;
			}
		}

		&__item--parent {
			display: flex;
				align-items: center;

			.submenu-toggle svg {transition: .25s transform;}

			&:hover,
			&:focus {
				&>.submenu-toggle svg {@include media($mobileNavBP) {transform: rotate(180deg);}}
			}
		}

		/**
		 * Submenu
		 */
		&__submenu {
			background-color: $charcoal;
			box-shadow: 0 0.5rem 1rem -0.25rem rgba($black, .6);
			opacity: 0;
			position: absolute;
			   top: 100%;
			   left: 0;
			transition: opacity .25s ease-in-out,
						visibility .25s ease-in-out;
			visibility: hidden;
			width: 18em;

			/**
			 * Submenus with open class and when hovering parent
			 */
			.open > &,
			.menu__item:hover > & {
				opacity: 1;
				transition: opacity .125s ease-in-out,
							visibility .125s ease-in-out;
				visibility: visible;
			}

			/**
			 * Submenu item styles
			 */
			.menu__item {
				border-bottom: 1px solid rgba($white, .2);
				margin: 0;

				&:last-child {border: none;}

				a {
                    font-size: 1rem;
					padding: 1em 1.25em;
					position: relative;
					transition: background .25s;
					width: 100%;

					// Submenu item background color on hover
					&:hover,
					&:focus {
						background-color: $bodyBackground; 

						&:after {
							transform: translate3d(.5rem, -50%, 0);
						}
					}

					&:after {
						@include size(1rem);
						background-image: url('/icons/arrow-right-teal.svg');
						background-position: right;
						background-repeat: no-repeat;
						background-size: 1rem;
						content: '';
						position: absolute;
							top: 50%;
							right: 1rem;
						transform: translate3d(0,-50%,0);
						transition: .25s transform;
					}
				}
			}
		}
	}
}