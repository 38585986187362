/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
$libraryPath: '';

// Colors
$white: #ffffff;
$grayLightest: #F0F3F5;
$grayLighter: #e5e5e5;
$gray: #363535;
$grayDarker: #232323;
$grayDarkest: #171616;
$charcoal: #303030;
$teal:#86D1D4;
$tealMid: #055C6E;
$tealDark:#376A72;
$tealDarker: #04424F;
$tealDarkest: #1d2e34;
$yellow: #FFDD8B;

$black: #000000;

$primaryColor: $teal;
$secondaryColor: $gray;

/**
 * Fonts
 */
$font-noto-serif: "Noto Serif", serif;
$font-poppins: "Poppins", "Helvetica", sans-serif;
$bodyFont: $font-poppins;
$headerFont: $font-noto-serif;

$textColor: $white;
$bodyBackground: $grayDarker;
$bodyFontSize: 1em;
$bodyLineHeight: 1.65;
$ratioScale: 1.25;
$linkColor: $textColor;

// Heading Sizing
$headingColor: $white;
$headingFontSize: 1em;
$headingLineHeight: 1.2;

// Layout Vars
$containSizeMoreNarrow: 50rem;
$containSizeNarrow: 65rem;
$containSize: 78rem;
$containSizeWide: 85rem;

$spacing: 1.5rem;
$vSpacing: 1.5rem;

// Media Query Break Points
$screen-xs:  30.000em;  //  480px
$screen-sm:  48.000em;  //  768px
$screen-md:  62.000em;  //  992px
$screen-lg:  75.000em;  // 1200px
$screen-xlg: 100.000em; // 1600px

// Max Breakpoints
$screen-xs-max:  $screen-xs  - .001em;
$screen-sm-max:  $screen-sm  - .001em;
$screen-md-max:  $screen-md  - .001em;
$screen-lg-max:  $screen-lg  - .001em;
$screen-xlg-max: $screen-xlg - .001em;

$mobileNavBP:    $screen-md; // Break mobile nav styles
$mobileNavBPMax: $screen-md-max;

// Animatinos
$animationDuration: .75s;

/**
 * Type style definitions
 */
%default-type {
	color: $textColor;
	font-family: $bodyFont;
	// letter-spacing: 0.025em;
}

%button-type {
	@extend %default-type;
	font-weight: bold;
}

%header-type {
	color: $headingColor;
    font-family: $headerFont;
	font-weight: 600;
	line-height: $headingLineHeight;
}

%nav-type {
	color: $headingColor;
    font-family: $bodyFont;
	font-weight: 600;
}
