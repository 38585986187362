/**
 * General styles and helpful classes
 * DBS>Interactive
 */

*,
*:after,
*:before,
input[type=search] {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}

// Tap highlight color
* { -webkit-tap-highlight-color: rgba($primaryColor, .3); }

// Text/image selection highlight color
::selection { background: rgba($primaryColor, .3); }

body {
	background: $bodyBackground;
	// Focus outline for site
	:focus {
		outline: none;
		box-shadow: 0 0 0 1px $bodyBackground, 0 0 0 2px $teal;
	}

    #backdrop,
    #floorplan-backdrop {
		content: '';
		background-color: rgba(0,0,0,.7);
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity .25s linear;
		z-index: 0;
		visibility: visible;
	}
	&.opened-menu,
	&.accessibility-menu-opened {
        #backdrop {
			opacity: 1;
			transition: opacity .25s linear;
			visibility: visible;
		}
	}
}


// Remove theme-specific focus color from the CMS
body.wp-admin :focus {
		box-shadow: none;
}
html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

address, ol, p, ul {
	margin: 0 0 $spacing;
}

ol ol, ol ul, ul ol, ul ul {
	margin: 0 0 $spacing;
}

hr {
	border: none;
	border-top: 1px solid $gray;
	display: block;
	height: 1px;
	margin: $spacing*2 0;
	padding: 0;
}

blockquote {
	border-left: .5rem solid $primaryColor;
	font-style: normal;
	font-weight: 400;
	margin: 2rem;
	max-width: 90%;
	overflow: hidden;
	padding: 1em;
	position: relative;
	text-align: left;

	@media screen and (min-width: $screen-sm) {
		max-width: 80%;
	}

	img.alignnone {
		border-radius: 100%;
		display: block;
		margin: 0 auto;
		overflow: hidden;
		text-align: center;

		@media screen and (min-width:$mobileNavBP) {
			display: inline-block;
			float: left;
			margin-right: 7%;
			max-height: 8em;
			max-width: 8em;
		}
	}

	p {
		margin-left: 2rem;
	}

	small {
		@include font-scale(-1, $bodyFontSize);
		display: inline-block;
		margin: 0 0 0.8em 1.5em;
		&:before { content: '\2014 \00A0'; }
	}

	cite { font-weight: bold; }
	cite a { font-weight: normal; }
}

p:last-child {
	margin-bottom: 0em;
}

dl {
	margin: 0 0 $spacing * .5;
	dt {
		font-weight: bold;
		margin: 0 0 $spacing * .5;
		padding-right: 1em;
	}

	dd {
		margin-bottom: $spacing * .5;
		margin-left: 2em;
	}
}


mark { background-color: $grayLighter; }

code, tt {
	@include font-scale(-1, $bodyFontSize);
	background: $grayDarkest;
	border-radius: 2px;
	border: 1px solid darken($gray,10%);
    color: $white;
	font-family: Inconsolata, monospace, sans-serif;
	padding: 1px 3px;
	white-space: pre;
}

pre {
	@include font-scale(-1, $bodyFontSize);
	background: $grayLighter;
	border-radius: 3px;
	border: 1px solid darken($grayLighter,10%);
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	font-family: Inconsolata, monospace, sans-serif;
	margin: 0 0 $spacing;
	overflow: auto;
	padding: 1em 1.25em;
	white-space: pre;
	width: 100%;
}

pre code, tt {
	background: transparent;
	border: none;
	font-size: inherit;
	padding: 0;
	white-space: -moz-pre-wrap;
	white-space: pre-wrap;
}

@include media($screen-sm) {
	pre {
		font-size: 0.8em;
	}
}

img, svg {
	height: auto;
	max-width: 100%;
}

svg {
	width: 100%; // Stabalize svg width if undefined
}

li > ol,
li > ul {
	margin-bottom: 0;
}

iframe {
	width: 100%;
}

/**
 * Helpful Re-usable Classes
 */



.site-logo {
	fill: $primaryColor;
	transition: fill ease-in-out 0.25s;
	&:hover {
		fill: lighten($primaryColor, 10%);
	}
}

.desktop-hidden {
	@include media ($screen-sm) {
		display: none !important;
	}
}

.mobile-hidden {
	@include media ($screen-sm-max, 'max') {
		display: none !important;
	}
}

// Since the mobile nav breakpoint is subject to change, these are sometimes useful.
.mobile-nav-visible {
	@include media($mobileNavBP) {display: none !important;}
}

.mobile-nav-hidden {
	@include media($mobileNavBPMax, 'max') {display: none !important;}
}

.lazy-load {
	max-width: 100%;
}

.linkclicker,
button,
input[type=submit] {
	cursor:pointer;
}

.alignright {
	float: right;
	margin-left: $spacing;
	margin-bottom: $spacing;
}

.alignleft {
	float: left;
	margin-right: $spacing;
	margin-bottom: $spacing;
}

@include media($screen-sm) {
	.alignright,
	.alignleft {
		&.size-large {
			max-width: 50%;
		}
	}
}

.aligncenter, .center {
	margin: $spacing auto;
	display: block;
}

.alignnone {
	margin: 0;
}

.left {
	float: left;
}

.right {
	float: right;
}

.hidden,
.hide { display: none !important; }

.assistive,
.hide-text,
.screen-reader-text {
    @include size(1px);
    background: $white;
    border: 0;
    color: $black;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;

	&.show-on-focus:focus {
		background-color: rgba(0,0,0,.9);
		border-radius: 1em;
		box-shadow: 0 0 3px 5px;
		color: $white;
		clip: unset;
		display: inline-block;
		height: auto;
		left: 50%;
		padding: 6em;
		position: fixed;
		top: 50%;
		transform: translate(-50%,-50%);
		transition: background-color .25s linear;
		width: auto;
		z-index: 99999;
	}
}

.hide-text { text-indent: -9999px; }

.two-column-list {
	@include media($screen-sm) {
		columns: 2;
		column-gap: 3rem;

		li,
		&__item {break-inside: avoid-column;}
	}
}

.embed-container,
.responsive-object {
	height: 0 !important;
	max-width: 100%;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100% !important;

	iframe {
		@include size(100%);
		position: absolute;
			top: 0;
			left: 0;
	}
}

.two-column-list {
	@include media($screen-sm) {
		columns: 2;
		column-gap: 3rem;

		li,
		&__item {
			break-inside: avoid-column;
			position: relative;
			z-index: 2; // Prevents overflow clipping issue on hover transforms
		}
	}
}

.link-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;

	&:after { // Covers the last row's bottom borders without having to consider number of children/odd-even children
		background: $white;
		content: '';
		height: 1px;
		position: absolute;
			bottom: 0;
			left: 0;
		width: 100%;
	}

	li {
		border-bottom: 1px solid $grayLightest;

		a {
			@extend .arrow-link;
			display: block !important;
			padding: 1.5rem 3rem 1.5rem 0 !important;
		}
	}

	&.two-column {@extend .two-column-list;}
}

.check-list {
	list-style: none;
	padding: 0;

	li {
		margin-bottom: .75rem;
		padding-left: 2rem;
		position: relative;

		&::before {
			@include size(1.25rem);
			background: url('/icons/teal-check.svg') no-repeat center;
				background-size: 1.25rem;
			content: '';
			position: absolute;
				top: .175rem;
				left: 0;
		}
	}

	@include media($screen-sm) {

		&.two-column {
			columns: 2;
				column-gap: 3rem;
		}
	}
}

.flickity-viewport,
.flickity-enabled,
.flickity-slider {box-shadow: none !important;}

.check-list {

}

.non-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

/**
 * Shortcode CTA Styles
 **/
 .blog-post__cta {
	border-bottom: 2px solid $teal;
	border-top: 2px solid $teal;
	float: none;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
	margin: 2em auto;
	padding: 1.5rem;
	position: relative;
	z-index: 1;
    
    h1, h2, h3, h4, h5, h6 {
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        font-weight: 500;
    }

	.button {
		margin-top: 1rem;
		width: 100%;
	}

	@include media($screen-sm) {
		border: none;
		border-left: 2px solid $teal;
		margin: $spacing $spacing 2em;
		max-width: 17em;
		float: right;

		.button {width: auto;}
	}

	em {
		color: fuchsia;
		line-height: 1;
		display: block;
		font-size: 2em;
	}

	&.left { @include media($screen-sm) { float: left; } }

	// An alternate version for multiple buttons that displays them in a vertical stack
	&.button-list {
		.button--teal {
			border-radius: 0;
			display: block;
			font-family: "Open Sans", "Verdana", "Helvetica", sans-serif;
			letter-spacing: 1px;
			margin: 0 -1em 3px 0em;
			max-width: none;
			padding: .75em;
			text-align: left;

			@include media($screen-sm) {
				width: calc( 100% + 2em);
			}

			&:after {border-radius: 0;}

			&:before {
				background: url('../../library/icons/src/circle-arrow.svg') no-repeat center;
					background-size: cover;
				content: '';
				height: 1.5rem;
				position: absolute;
					top: 50%;
					right: .75em;
				transform: translate(0,-50%);
				transition: .3s ease-in-out;
				width: 1.5rem;
			}
	
			&:hover,
			&:focus { 
				background: $teal;
				color: $white;

				&:before {transform: translate( .5rem, -50% );}
			}
	
			&:first-of-type {margin-top: 1em;}
	
			& + br {display: none;}

			&.black-button {
				background: #000;
				border-color: #000;

				&:after {content: none;}

				&:hover,
				&:focus {
					color: $white;
				}
			}
		}
	}
}

// Re-usable "What's Included" list component for sidebars
.whats-included-list {
	button {
		background: $grayLightest;
		border: none;
		padding: 1rem;
		position: relative;
		text-align: left;
		transition: .3s;
		width: 100%;

		&:hover {
			background: $grayLighter;
		}

		&:after {
            background: url('../icons/arrow-right-teal.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            content: '';
			display: block;
			height: 1.75rem;
			position: absolute;
				top: 50%;
				right: 1rem;
			transition: transform .3s;
            transform: translate(0, -50%) scale(.8) rotate(90deg);
			width: 1.75rem;
		}

		&.active {
			&:after {
				transform: translate(0, -50%) scale(.8) rotate(270deg);
			}
		}
	}

	h3 {
		color: #1D2227;
		margin: 0;
		text-transform: uppercase;
	}

	ul {
		border-top: 2px solid $white;
		list-style: none;
		margin: 0;
		padding: 0;
        height: 100%;
        max-height: 0;
        overflow: hidden;
        transition: all .35s ease-in-out;
	}

	li {
		border-top: 2px solid #6e7279;
		padding: .5rem 1rem;
		&:first-child {border-top: none;}
	}
}

.right {float: right;}

.left {float: left;}

.full-width-ribbon {
    background-color: $grayDarker;
    color: $white;
    padding: #{$spacing * 2} 0;
    width: 100%;
    
    h2 {
        color: $white !important;
        margin-bottom: #{$spacing * 2};
    }
    
    &__kits {
        display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        
    @include media($screen-md) {
        &__kits {
            justify-content: center;
        }
    }
        
    @include media($screen-lg) {
        &__kits {
            flex-wrap: nowrap;
        }
    }
}

.kit-shortcode {
    text-align: center;
    width: calc(50% - #{$spacing});
    
    &__image {
        margin-bottom: #{$spacing / 2};
    }
    
    &__link {
        a {
            color: $white !important;
        }
        
        .arrow-link {
            &::after {
                background-image: url('/icons/circle-arrow-dark-teal.svg');
                transition: transform .4s ease-in-out;
            }
        }
    }
    
    @include media($screen-sm) {
        margin-bottom: #{$spacing * 2};
        width: calc(100% / 3);
    }
    
    @include media($screen-md) {
        width: 25%;
    }
        
    @include media($screen-lg) {
        margin-bottom: 0;
        width: auto;
        
        &:not(:last-child) {
            margin-right: #{$spacing * 2};
        }
    }
}

.shoulder-shortcode {
    display: flex;
        align-items: center;
    padding-right: 8rem;
    position: relative;
    
    a {
        text-decoration: none;
    }
    
    &__image {
        margin-right: $spacing;
        
        img {
            margin-bottom: 0 !important;
        }
    }
    
    &__text {
        display: flex;
            flex-direction: column;
            
        h3 {
            margin-bottom: 0;
        }
            
        hr {
            border-top: 2px solid $tealDark;
            margin: #{$spacing / 3} 0;
            width: #{$spacing * 3};
        }
    }
    
    &.bg-dark-teal {
        border-radius: 4rem;
        padding: .5rem 8rem .5rem 1.5rem;
        
        .shoulder-shortcode__text {
            color: white;
            
            h3 {
                color: white;
            }
        }
        
        &::after {
            background: url('/icons/circle-arrow-white-v2.svg') no-repeat;
            right: 1.5rem;
        }
    }
    
    &.float-left {
        float: left;
        margin-right: $spacing;
    }
    
    &.float-right {
        float: right;
        margin-left: $spacing;
    }
    
    &.solo {
        background-color: $grayDarker;
        border-radius: 2rem;
        padding: 1rem 6rem 1rem 1rem;
        
        .shoulder-shortcode__text {
            color: white;
            font-weight: 700;
            
            h3 {
                color: white;
            }
        }
        
        &::after {
            background: url('/icons/circle-arrow-down.svg') no-repeat;
            height: 2.5rem;
            right: 1.5rem;
            transition: transform .4s ease-in-out;
            width: 2.5rem;
        }
        
        &:active,
        &:focus,
        &:hover {
            &::after {
                transform: translate(0, calc(-50% + .25rem));
            }
        }
    }
    
    &::after {
        background: url('/icons/circle-arrow-fade.svg') no-repeat;
        background-color: $grayDarker;
        border-radius: 50%;
        content: '';
        height: 3.75rem;
        position: absolute;
            top: 50%;
            right: 0;
        transform: translateY(-50%);
        transition: transform .4s ease-in-out;
        width: 3.75rem;
    }
    
    &:active,
    &:focus,
    &:hover {
        &::after {
            transform: translate(.5rem, -50%);
            transition: transform .4s ease-in-out;
        }
    }
    
    @include media($screen-xs-max, 'max') {
        margin-bottom: $spacing;
        padding-right: 4.5rem;
        
        &.bg-dark-teal {
            padding: .5rem 5.5rem .5rem 1.5rem;
            
            &::after {
                background-position: center;
                height: 3rem;
                right: 1rem;
                width: 3rem;
            }
        }
        
        &.float-left {
            margin-right: 0;
        }
        
        &.float-right {
            margin-left: 0;
        }
    }
}

.kit-cta {
    background-color: $grayDarker;
    display: flex;
    padding: $spacing;
    width: fit-content;
    
    a {
        text-decoration: none;
    }
    
    &__text {
        display: flex;
            flex-direction: column;
            
        h3 {
            color: $white !important;
            margin-bottom: 0;
        }
            
        hr {
            border-top: 2px solid $tealDark;
            margin: #{$spacing / 2} 0;
            width: #{$spacing * 3};
        }
    }
    
    &.image-left,
    &.image-right {
        align-items: center;
        max-height: 14.5rem;
        max-width: 40rem;
        
        .kit-cta__image {
            margin-right: $spacing;
            
            img {
                margin-bottom: 0 !important;
            }
        }
        
        .button {
            background-color: $tealDark;
            border-color: $tealDark;
            color: $white;
            padding: .75rem 5rem .75rem 1rem;
            position: relative;
            width: fit-content;
            
            &::before {
                height: 2rem;
                transition: transform .4s ease-in-out;
                width: 2rem;
            }
            
            &::after {
                content: none;
            }
            
            &:active,
            &:focus,
            &:hover {
                transition: transform .5s ease-in-out;
                
                &::before {
                    opacity: 1;
                }
            }
            
            &.arrow-down {
                &::before {
                    background: url('/icons/circle-arrow-down-black.svg') no-repeat center;
                }
                
                &:active,
                &:focus,
                &:hover {
                    
                    &::before {
                        background: url('/icons/circle-arrow-down-black.svg') no-repeat center;
                        transform: translate(0, calc(-50% + .25rem));
                    }
                }
            }
            
            &.arrow-right {
                &::before {
                    background: url('/icons/circle-arrow-black.svg') no-repeat center;
                    background-size: 100%;
                }
                
                &:active,
                &:focus,
                &:hover {
                    
                    &::before {
                        background: url('/icons/circle-arrow-black.svg') no-repeat center;
                        background-size: 100%;
                    }
                }
            }
        }
    }
    
    &.image-left {
        flex-direction: row;
    }
    
    &.image-right {
        flex-direction: row-reverse;
    }
    
    &.image-top {
        display: inline-block;
        flex-direction: column;
        
        .kit-cta__text {
            position: relative;
            
            a {
                color: #cdcdcd;
                font-weight: 500;
            }
            
            &:active,
            &:focus,
            &:hover {
                color: darken(#cdcdcd, 10%);
                
                &::after {
                    transform: translate(.5rem, -50%);
                }
            }
            
            &::after {
                background: url('/icons/circle-arrow-fade.svg') no-repeat;
                background-color: $grayDarker;
                border-radius: 50%;
                content: '';
                height: 3.75rem;
                position: absolute;
                    top: 50%;
                    right: 0;
                transform: translateY(-50%);
                transition: transform .4s ease-in-out;
                width: 3.75rem;
            }
        }
    }
}

.text-cta {
    padding: $spacing;
    
    &__text {
        h3 {
            color: $white !important;
        }
        
        hr {
            border-top: 2px solid $tealDark;
            margin: #{$spacing / 2} 0;
            width: #{$spacing * 3};
        }
    }
    
    &.size-small,
    &.size-medium {
        background-color: $grayDarker;
        overflow: hidden;
        
        .text-cta__text {
            a {
                display: block;
                font-weight: 500;
                position: relative;
                text-decoration: none;
                
                &::after {
                    background: url('/icons/circle-arrow-fade.svg') no-repeat;
                    background-color: $grayDarker;
                    border-radius: 50%;
                    content: '';
                    position: absolute;
                        top: 50%;
                    transform: translateY(-50%);
                    transition: transform .4s ease-in-out;
                }
            }
        }
    }
    
    &.size-small {
        height: 15.5rem;
        width: 16.5rem;
        position: relative;
        
        h3 {
            font-size: 1.375rem;
        }
        
        .text-cta__image {
            height: 80%;
            pointer-events: none;
            position: absolute;
                bottom: -#{$spacing};
                left: 50%;
            transform: translateX(-50%);
            width: 80%;
        }
        
        .text-cta__text {
            display: flex;
                flex-direction: column;
            height: 100%;
                
            a {
                color: $white;
                margin: auto $spacing $spacing 0;
                
                &:active,
                &:focus,
                &:hover {
                    color: darken(#cdcdcd, 10%);
                    
                    &::after {
                        transform: translate(.5rem, -50%);
                    }
                }
                
                &::after {
                    height: 3.75rem;
                    right: 0;
                    width: 3.75rem;
                }
            }
        }
    }
    
    &.size-medium {
        max-height: 18.5rem;
        max-width: 30rem;
        
        h3 {
            font-size: 2.75rem;
        }
        
        .text-cta__text {
            a {
                color: #cdcdcd;
                
                &:active,
                &:focus,
                &:hover {
                    transition: transform .5s ease-in-out;
                    
                    &::after {
                        transform: translate(1rem, -50%);
                    }
                }
            }
        }
        
        @media (min-width: 30em) {
            .text-cta__text {
                a {
                    &::after {
                        background-size: 100%;
                        height: 11.4rem;
                        right: -3.8rem;
                        width: 11.4rem;
                    }
                }
            }
        }
        
        @media (max-width: 29.999em) {
            max-height: none;
            max-width: none;
            min-width: 16.5rem;
            
            .text-cta__text {
                a {
                    padding-right: 5.25rem;
                    
                    &::after {
                        background-size: 100%;
                        height: 3.75rem;
                        right: 0;
                        width: 3.75rem;
                    }
                }
            }
        }
    }
    
    &.size-large {
        background: linear-gradient(270deg, #3F757D 0%, rgba(63, 117, 125, 0.00) 120.45%), #303030;
        display: flex;
        max-height: 22rem;
        max-width: 80rem;
        overflow: hidden;
        padding: 0;
        
        h3 {
            font-size: 3.25rem;
        }
        
        .text-cta__image,
        .text-cta__text {
            width: 50%;
        }
        
        .text-cta__image {
            position: relative;
        }
        
        .text-cta__image img {
            height: 100%;
            object-fit: contain;
            position: absolute;
                right: -15%;
                bottom: -10%;
            width: 100%;
        }
        
        .text-cta__text {
            padding: #{$spacing * 3} 0 #{$spacing * 3} 3.8rem;
            
            a {
                color: $white;
                font-size: 1.75rem;
                position: relative;
                text-decoration: none;
                
                &:active,
                &:focus,
                &:hover {
                    
                    &::after {
                        transform: translate(.5rem, -50%);
                    }
                }
                
                &::after {
                    background: url('/icons/circle-arrow-white.svg');
                    content: '';
                    height: 4rem;
                    position: absolute;
                        top: 50%;
                        right: -9rem;
                    transform: translate(0, -50%);
                    transition: transform .4s ease-in-out;
                    width: 4rem;
                }
            }
        }
        
        @media (max-width: 74.999em) {
            flex-direction: column;
            max-height: none;
            max-width: none;
            
            .text-cta__image {
                width: 100%;

                img {
                    position: relative;
                    right: unset;
                }
            }
                        
            .text-cta__text {
                padding: 3rem;
                width: 100%;
                
                a {
                    padding-right: 5rem;
                    
                    &::after {
                        right: 0;
                    }
                }
            }
        }
        
        @media (max-width: 39.999em) {
            .text-cta__text {
                a {
                    padding-right: 0;
                    
                    &::after {
                        left: 0;
                        right: unset;
                        top: calc(100% + 3rem);
                    }
                }
            }
        }
    }
}

.banner {
    background-color: $grayDarker;
    display: flex;
        align-items: center;
    width: fit-content;
    
    &__image {
        height: 100%;
        margin-right: $spacing;
        max-width: 12rem;
        width: auto;
        
        img {
            display: block;
        }
    }
    
    &__text {
        a {
            color: $white !important;
            display: block;
            padding-right: #{$spacing * 4};
            position: relative;
            text-decoration: none;
            margin: auto #{$spacing} auto 0;
            
            &:active,
            &:focus,
            &:hover {
                color: darken(#cdcdcd, 10%);
                
                &::after {
                    transform: translate(.5rem, -50%);
                }
            }
            
            &::after {
                height: 3.75rem;
                right: 0;
                width: 3.75rem;
            }
            
            &::after {
                background: url('/icons/circle-arrow-fade.svg') no-repeat;
                background-color: $grayDarker;
                border-radius: 50%;
                content: '';
                position: absolute;
                    top: 50%;
                transform: translateY(-50%);
                transition: transform .4s ease-in-out;
            }
        }
    }
}

.house-list {
    @extend .check-list;
    font-size: 1.125rem;

    li {
        border-bottom: 1px solid #CACACA;
		padding-bottom: .75rem;
        padding-top: .75rem;
        padding-left: 3rem;

		&::before {
			@include size(2.5rem);
			background: url('/icons/house.svg') no-repeat center;
				background-size: 1.75rem;
			content: '';
		}
	}
}

.bg-dark .house-list {
    li {border-color: rgba(202,202,202,.15);}
}


.number-heading,
.house-heading {
    color: $grayDarker;
    display: flex;
        align-items: center;
    font-family: $font-poppins;
    font-weight: 500;
    line-height: 1.75;
    margin-bottom: $spacing;

    &__number,
    &__house {
        color: $tealDark;
        display: flex;
            align-items: center;
        font-size: 3rem;
        position: relative;

        &::after {
            background: linear-gradient(180deg, #376A72 0%, #68C9D8 100%);
            height: 4rem;
            content: '';
            margin: 0 1.5rem;
            width: 3px;

        }
    }

    &__spacer {
        background: url('/icons/house.svg') no-repeat center;
            background-size: 4rem;
        height: 4rem;
        width: 4rem;
    }
}

.house-cta {
    border-radius: 20px;
    box-shadow: 0px 2px 29px 0px rgba(0, 0, 0, 0.15);
    display: flex;
        align-items: center;
        flex-wrap: wrap;
    padding: $spacing;

    &__house {
        background: url('/icons/house.svg') no-repeat center;
            background-size: 4rem;
        height: 4rem;
        margin-bottom: 1rem;
        margin-right: 1.5rem;
        min-width: 5.5rem;
        width: 5.5rem;
    }

    &__text {
        margin-bottom: 1.5rem;
    }

    @include media($screen-sm) {
        flex-wrap: nowrap;

        &__house {
            margin-bottom: 0;
        }

        &__text {
            margin-bottom: 0;
            margin-right: 1.5rem;
            width: auto;
        }

        &__button {
            margin-left: auto;
        }
    }
}
